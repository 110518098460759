import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
export const ImageProvider: React.FC<{
  fileName: string;
  className?: string;
  imgStyle?: any;
}> = ({ className, fileName, imgStyle }) => {
  const { allImageSharp } = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          fluid {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const fluid = allImageSharp.nodes.find(
    (n: { fluid: { originalName: string } }) =>
      n.fluid.originalName === fileName
  ).fluid;
  return <Img className={className} fluid={fluid} imgStyle={imgStyle} />;
};
