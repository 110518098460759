import React, { ReactNode } from "react";
import {
  documentToReactComponents,
  NodeRenderer,
  Options,
  RenderNode,
} from "@contentful/rich-text-react-renderer";
import {
  BLOCKS,
  INLINES,
  Document as ContenfulDocument,
  MARKS,
  Block,
  Inline,
} from "@contentful/rich-text-types";

import { Link, makeStyles, Typography } from "@material-ui/core";
import { InvertColors } from "@material-ui/icons";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 700,
  },
  footnote: { cursor: "pointer", color: theme.palette.secondary.main },
  heading: {
    color: theme.palette.secondary.main,
  },
  italic: {},
  li: {},
  link: {
    color: "inherit",
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
  paragraph: {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    marginBottom: theme.spacing(1),
  },
  paragraphFootnotes: {
    marginBottom: 2,
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
  ol: { marginBottom: theme.spacing(1) },
  ul: { marginBottom: theme.spacing(1) },
  smallFont: {
    fontSize: 12,
  },
  underline: {},
}));

export const TextRenderer: React.FC<{
  data: ContenfulDocument;
  smallText?: boolean;
  inverseColor?: boolean;
  footnoteCallback?: (id: string) => void;
}> = ({ data, footnoteCallback, inverseColor, smallText }) => {
  const {
    bold,
    footnote,
    heading,
    italic,
    li,
    link,
    ol,
    paragraph,
    paragraphFootnotes,
    smallFont,
    underline,
    ul,
  } = useStyles();
  let footnoteIndex = 1;
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text: string) => (
        <Typography
          component="b"
          className={clsx(bold, smallText ? smallFont : "")}
        >
          {text}
        </Typography>
      ),
      [MARKS.ITALIC]: (text: string) => (
        <Typography
          component="i"
          className={clsx(italic, smallText ? smallFont : "")}
        >
          {text}
        </Typography>
      ),
      [MARKS.UNDERLINE]: (text: string) => (
        <Typography
          component="u"
          className={clsx(underline, smallText ? smallFont : "")}
        >
          {text}
        </Typography>
      ),
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: ReactNode) => (
        <Typography
          className={clsx(
            smallText ? [paragraphFootnotes, smallFont] : paragraph
          )}
        >
          {children}
        </Typography>
      ),
      [BLOCKS.LIST_ITEM]: (node: Block | Inline, children: ReactNode) => (
        <Typography
          component="li"
          className={clsx(li, smallText ? smallFont : "")}
        >
          {children}
        </Typography>
      ),
      [BLOCKS.UL_LIST]: (node: Block | Inline, children: ReactNode) => (
        <Typography
          component="ul"
          className={clsx(ul, smallText ? smallFont : "")}
        >
          {children}
        </Typography>
      ),
      [BLOCKS.OL_LIST]: (node: Block | Inline, children: ReactNode) => (
        <Typography
          component="ol"
          className={clsx(ol, smallText ? smallFont : "")}
        >
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_1]: (node: Block | Inline, children: ReactNode) => (
        <Typography
          variant="h1"
          className={clsx(heading, smallText ? smallFont : "")}
        >
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_2]: (node: Block | Inline, children: ReactNode) => (
        <Typography
          variant="h2"
          className={clsx(heading, smallText ? smallFont : "")}
        >
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_3]: (node: Block | Inline, children: ReactNode) => (
        <Typography
          variant="h3"
          className={clsx(heading, smallText ? smallFont : "")}
        >
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_4]: (node: Block | Inline, children: ReactNode) => (
        <Typography
          variant="h4"
          className={clsx(heading, smallText ? smallFont : "")}
        >
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_5]: (node: Block | Inline, children: ReactNode) => (
        <Typography
          variant="h5"
          className={clsx(heading, smallText ? smallFont : "")}
        >
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_6]: (node: Block | Inline, children: ReactNode) => (
        <Typography
          variant="h6"
          className={clsx(heading, smallText ? smallFont : "")}
        >
          {children}
        </Typography>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
        const { title, description } = node.data.target.fields;
        return null;
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
        const { title, description } = node.data.target.fields;
        return null;
      },
      [INLINES.HYPERLINK]: (node: Block | Inline, children: ReactNode) => {
        //numeritos(indices) en el texto
        return (
          <Link
            className={clsx(link, smallText ? smallFont : "")}
            href={node.data.uri}
            target="_blank"
          >
            {children}
          </Link>
        );
      },
      [INLINES.ASSET_HYPERLINK]: (
        node: Block | Inline,
        children: ReactNode
      ) => {
        return null;
      },
      [INLINES.ASSET_HYPERLINK]: (
        node: Block | Inline,
        children: ReactNode
      ) => {
        return null;
      },
      [INLINES.ENTRY_HYPERLINK]: (
        //numeritos(indices) en el texto
        node: Block | Inline,
        children: ReactNode
      ) => {
        return (
          <sup
            className={footnote}
            onClick={() =>
              footnoteCallback && footnoteCallback(node.data.target.sys.id)
            }
          >
            {footnoteIndex++}
          </sup>
        );
      },
    },
    renderText: (text: string) => text,
  };
  return (
    <div
      style={{
        color: inverseColor ? "#fff" : "inherit",
      }}
    >
      {documentToReactComponents(data, options as Options)}
    </div>
  );
};
