import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  typography: {
    h2: { fontWeight: 700, fontSize: "1rem", margin: "1rem 0 1rem 0" },
    body1: { fontSize: "1rem" },
    body2: { fontSize: "0.9rem" },
  },
  palette: { secondary: { main: "#d4015d" } },
  //permanent marker
  overrides: {
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: 0,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(255, 255, 255, 0.8)", //this is for the menu drawer
        height: "calc(100vh - 64px)",
        top: 64,
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      root: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiButton: {
      root: {
        minWidth: 0,
        padding: 0,
      },
      text: {
        padding: 0,
      },
    },
    MuiAccordion: {
      root: {
        backgroundColor: "unset",
      },
    },
    MuiPaper: {
      elevation1: {
        // boxShadow: "unset",  //this is for the accordion
      },
      elevation16: {
        boxShadow: "2px 2px 20px 0px grey",
      },
    },
  },
});
